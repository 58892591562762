<template>
    <div class="pathology-list" >
        <template v-if="pathologyList && pathologyList.length > 0">
            <PathologyItem v-for="(item, idx) in pathologyList" :key="idx" :pathology="item"></PathologyItem>
        </template>
        <template v-else>
            <div class="no-follow">
                <p>{{$t('patient.noFollow')}}</p>
            </div>
        </template>
    </div>
</template>

<script>
import PathologyItem from './../../components/patient/PathologyItem'
import axios from 'axios'
import store from './../../store'

export default {
    name:'PathologyList',
    components : {
        PathologyItem
    },
    data() {
        return {
            pathologyList : []
        }
    },
    mounted() {
        let config = {
             validateStatus: () => true,
        }
        if(store.getters.getToken) {
            config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
        }

        let vm = this

        axios
            .get(store.getters.getURL +"monitoring/" + store.state.USER.infos.id, config)
            .then(function (response) {
                vm.pathologyList = response.data
            })
            .catch((err) => {
                store.dispatch('addNotif', {type : 2, content : err})
            });
    }
}
</script>

<style lang="scss" scoped>
    .pathology-list {
        margin-bottom:20px;
    }

    .no-follow {
        display: flex;
        justify-content: center;
    }

    .no-follow p {
        text-align: center;
        font-size:20px;
        color:$textColor;
    }
</style>